import classNames from 'classnames';
import DescriptionContent from 'components/ProductDetail/SubNavContent/DescriptionContent';
import PriceDisclaimerContent from 'components/ProductDetail/SubNavContent/PriceDisclaimerContent';
import RatingContent from 'components/ProductDetail/SubNavContent/RatingContent';
import ProductDetailIframe from 'components/ProductDetailIframe';
import Sidebar from 'components/Sidebar';
import Account from 'components/Sidebar/content/Account';
import SidebarRecipes from 'components/SidebarRecipes';
import SocialShare from 'components/SocialShare';
import NotificationContainer from 'containers/NotificationContainer';
import { VIEW } from 'helper/js/constants';
import {
  useFlippyContentId,
  useFlippyNavigate,
  useFlippyPageNumber,
  useFlippyView,
} from 'helper/js/hooks/router';
import useEventListener from 'helper/js/hooks/use-event-listener';
import { LanguageContext } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { lazy, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { flyerWishListCount } from './selectors';

const OverviewContainer = lazy(() => import('containers/OverviewContainer'));
const MenuContainer = lazy(() => import('containers/MenuContainer'));
const RecommendedContainer = lazy(() => import('containers/RecommendedContainer'));
const WishListContainer = lazy(() => import('containers/WishListContainer'));
const SearchContainer = lazy(() => import('containers/SearchContainer'));
const Topics = lazy(() => import('components/Topics'));
const ProductOverviewDataContainer = lazy(() => import('containers/ProductOverviewDataContainer'));
const ProductDetailContainer = lazy(() => import('containers/ProductDetailContainer'));

export type SidebarContenPage = {
  component: JSX.Element[];
  header: string[];
};

function SidebarContainer() {
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);
  const { t } = useContext(LanguageContext);

  const state = useSelector((_state: AppState) => _state);
  const flippyView = useFlippyView();
  const flippyPageNumber = useFlippyPageNumber();
  const flippyContentId = useFlippyContentId();
  const isSm = useSelector((_state: AppState) => _state.browser.isSmMin);
  const isMdMin = useSelector((_state: AppState) => _state.browser.isMdMin);
  const isSharingEnabled = useSelector((_state: AppState) => _state.settings.enableSharing);
  const hasTopics = useSelector((_state: AppState) => _state.settings.hasTopics);
  const flippyNavigate = useFlippyNavigate();
  const [sideBarHistory, setSideBarHistory] = useState<VIEW[]>([]);
  const clientLocale = useSelector((_state: AppState) => _state.appData.clientLocale);

  const wishListCount = flyerWishListCount(state);

  const showOverviewContent = flippyView === VIEW.OVERVIEW && state.browser.isSmMin;
  const showRecommendedContent = flippyView === VIEW.RECOMMENDED;
  const showMenuContent = flippyView === VIEW.MENU;
  const showSearchContent = flippyView === VIEW.SEARCH;
  const showWishlistContent = flippyView === VIEW.WISHLIST;
  const showJumpmarks = flippyView === VIEW.JUMPMARKS;
  const showAccount = flippyView === VIEW.ACCOUNT;
  const showProductContent = flippyView === VIEW.PRODUCTDETAILS;

  useEffect(() => {
    setSideBarHistory((prev: VIEW[]) => [...prev, flippyView]);
  }, [showProductContent, flippyView]);

  const isSidebarVisible = (): boolean => {
    return (
      (showProductContent && flippyContentId !== '') ||
      showSearchContent ||
      showRecommendedContent ||
      showMenuContent ||
      showAccount ||
      showWishlistContent ||
      (showJumpmarks && !isMdMin) ||
      showOverviewContent
    );
  };

  const handleClose = () => {
    let eventLabel = '';
    if (showMenuContent) {
      eventLabel = 'menu';
    } else if (showOverviewContent) {
      eventLabel = 'overview';
    } else if (showSearchContent) {
      eventLabel = 'search';
    } else if (showWishlistContent) {
      eventLabel = 'wishlist';
    } else if (showProductContent) {
      eventLabel = 'product detail';
    }

    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.OVERLAY_BACK_BUTTON_CLICKED,
      eventLabel,
    });
    setSideBarHistory([]);

    flippyNavigate({
      view: isMdMin && hasTopics ? VIEW.JUMPMARKS : VIEW.FLYER,
      contentId: undefined,
    });
  };

  const handlePageNumberChange = (targetPage: number) => {
    flippyNavigate({ page: `${targetPage}` });
  };

  const handleWishListNavigate = (prodId: number) => {
    flippyNavigate({
      view: VIEW.PRODUCTDETAILS,
      contentId: `${prodId}`,
    });
  };

  const handleRecommendedFlyerNavigate = (uuid: string) => {
    flippyNavigate({
      uuid,
      page: '1',
      view: VIEW.FLYER,
      contentId: undefined,
    });
  };

  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose],
  );

  // attach event listener only when sidebar is open
  useEventListener('keydown', handleEscapeKey, isSidebarVisible() ? window : null);

  const getContentClassName = () =>
    classNames({
      'sidebarpage--grey': showMenuContent,
      'sidebarpage--flex': showOverviewContent || showMenuContent || showProductContent,
      'sidebarpage--column': showMenuContent || showProductContent,
      'sidebarpage--column-space-between': showMenuContent,
      'sidebarpage--wishlist': showWishlistContent && wishListCount !== null,
      'sidebarpage--overview': showOverviewContent,
    });

  const getContent = (): SidebarContenPage => {
    if (showOverviewContent) {
      return {
        component: [
          <Suspense fallback={null}>
            <OverviewContainer
              handlePageNumberChange={handlePageNumberChange}
              currentPage={flippyPageNumber}
            />
          </Suspense>,
        ],
        header: [t('overview')],
      };
    }
    if (showRecommendedContent) {
      return {
        component: [
          <Suspense fallback={null}>
            <RecommendedContainer changeFlyer={handleRecommendedFlyerNavigate} />
          </Suspense>,
        ],
        header: [t('recommendationFlyoutHeadline')],
      };
    }
    if (showMenuContent) {
      return {
        component: [
          <Suspense fallback={null}>
            <NotificationContainer />
            <MenuContainer />
            {isSharingEnabled && (
              <SocialShare
                shareUrl={window.location.href.replace('view/menu', 'view/flyer')}
                headline={t('shareFlyerHeadline')}
              />
            )}
          </Suspense>,
        ],
        header: [t('menu')],
      };
    }
    if (showWishlistContent) {
      return {
        component: [
          <Suspense fallback={null}>
            <WishListContainer updateUrl={handleWishListNavigate} />
          </Suspense>,
        ],
        header: [(!wishListCount ? '' : `(${wishListCount}) `) + t('wishListButton')],
      };
    }

    if (showSearchContent) {
      return {
        component: [
          <Suspense fallback={null}>
            <SearchContainer />
          </Suspense>,
        ],
        header: [t('search')],
      };
    }
    if (showJumpmarks) {
      return {
        component: [
          <Suspense fallback={null}>
            <Topics />
          </Suspense>,
        ],
        header: [t('jumpmarksButton')],
      };
    }
    if (showAccount) {
      return {
        component: [
          <Suspense fallback={null}>
            <Account />
          </Suspense>,
        ],
        header: [t('myAccount')],
      };
    }
    if (flippyContentId) {
      if (clientLocale === 'lidl/es-ES') {
        return {
          component: [
            <Suspense fallback={null}>
              <ProductOverviewDataContainer
                dataType={'ProductDetail'}
                key={flippyContentId}
                productIds={[flippyContentId]}>
                {({ data }) => <ProductDetailContainer productData={data[0]} />}
              </ProductOverviewDataContainer>
            </Suspense>,
            <SidebarRecipes />,
            <RatingContent />,
            <DescriptionContent />,
            <PriceDisclaimerContent />,
          ],
          header: [t('productdetail'), t('recipes'), t('ratings'), t('productDescription')],
        };
      } else {
        return {
          component: [
            <Suspense fallback={null}>
              <ProductDetailIframe productId={flippyContentId} />
            </Suspense>,
          ],
          header: [t('productdetail')],
        };
      }
    }

    return {
      component: [],
      header: [],
    };
  };

  return (
    <Sidebar
      hasContent={isSidebarVisible() && !state.appData.error}
      onClose={handleClose}
      contentClassName={getContentClassName()}
      pageContent={getContent()}
      view={flippyView || ''}
      isSm={isSm}
      history={sideBarHistory}
    />
  );
}
export default SidebarContainer;
