import Product from 'service/Product';
import ProductVariant from 'service/ProductVariant';

import { TRANSITION_TYPES } from '../../containers/SheetContainer/constants';

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const emptyArray: any[] = [];
export const emptyObject: IKeyValueData = {};

export const addSearchParamsToUrl = (
  url: string,
  additionalParams: { [key: string]: string | undefined },
): string => {
  try {
    const urlObj = new URL(url, window.location.href);
    const existingParams = new URLSearchParams(urlObj.search);

    for (const [key, value] of Object.entries(additionalParams)) {
      if (!existingParams.has(key) && value !== undefined && value !== '') {
        existingParams.append(key, value);
      }
    }
    urlObj.search = existingParams.toString();
    return urlObj.toString();
  } catch (error) {
    console.error(`Error adding search params to URL: ${error}`);
    return url;
  }
};

export const filterUniqueElementsFromArray = (stringArray: string[]) => {
  return [...new Set(stringArray)];
};

export const validateNavigation = (
  currentPage: number,
  direction: string,
  pagesLength: number,
  isWidescreen: any,
) => {
  switch (direction) {
    case 'forward':
      return currentPage !== 1 && currentPage >= pagesLength - isWidescreen;
    case 'backward':
      return currentPage <= 1;
    default:
      return false;
  }
};

export const getSearchParamsAsObject = (searchLocation = window.location.search): IKeyValueData => {
  if (!searchLocation) {
    return {};
  }

  const searchQuery = searchLocation.slice(1);
  return searchQuery
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, [key, value]) => {
      return {
        ...obj,
        [key]: value === 'true' ? true : value,
      };
    }, {});
};

/** used in NotificationContainer only */
export const http = <T>(
  request: RequestInfo,
  config: RequestInit = {},
): Promise<IHttpResponse<T>> => {
  return new Promise((resolve, reject) => {
    let response: IHttpResponse<T>;
    fetch(request, config)
      .then((res) => {
        response = res;
        if (!res.ok) {
          return Promise.resolve({});
        }
        return res.json();
      })
      .then((body) => {
        if (response.ok && response.status === 200) {
          response.parsedBody = body;
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err: Error) => {
        console.log(err, request);
        reject(err);
      });
  });
};

export const compareReactNodes = (nodes: any = [], nextNodes: any = []) => {
  if (nodes.length !== nextNodes.length) {
    return true;
  }
  for (let i = 0; i < nodes.length; i++) {
    const current: any = nodes[i];
    const prevCurrentPage: any = nextNodes[i];
    if (current.key !== prevCurrentPage.key) {
      return true;
    }
  }
  return false;
};

export const getBaseWithoutUuid = () => {
  const { uuid } = getBaseAndUuidBeforePath();
  const urlArray = window.location.href.split('/');
  let baseUrl = window.location.origin;
  if (uuid) {
    const uuidIndex = urlArray.indexOf(uuid);
    baseUrl = urlArray.slice(0, uuidIndex).join('/');
  }
  return baseUrl;
};

export const getBaseAndUuidBeforePath = () => {
  const { pathname } = window.location;
  const localeArRe = /(\/locale\/|\/(ar|store)\/|\/view\/|\/page\/).*/;
  const locale = localeArRe.exec(pathname);
  let normalizedPathname = pathname;
  if (locale?.[0]) {
    normalizedPathname = pathname.replace(locale[0], '');
  }
  normalizedPathname = normalizedPathname.endsWith('/')
    ? normalizedPathname.substring(0, normalizedPathname.length - 1)
    : normalizedPathname;
  const uuidRe = /\/(.*)\/view/;
  const base = uuidRe.exec(normalizedPathname);

  if (base && typeof base[1] === 'string') {
    return getBaseAndUuidFromPath(base[1]);
  }
  return getBaseAndUuidFromPath(normalizedPathname);
};
function getBaseAndUuidFromPath(path: string = window.location.pathname) {
  const splitted = path.split('/');
  const uuid = splitted.pop();

  return {
    base: splitted.join('/'),
    uuid,
  };
}

export const doesEventContainsElement = (nativeEvent: any, classNames: string[] | string = []) => {
  const eventPath = nativeEvent.composedPath?.() ?? [];
  const classNamesAsArray = typeof classNames === 'string' ? [classNames] : classNames;
  if (!eventPath) {
    return true;
  }
  return eventPath.some((pathItem: any) => {
    return (
      pathItem.classList &&
      classNamesAsArray.some((className) => pathItem.classList.contains(className))
    );
  });
};

export const getNormalizedApiUrl = (url: string, countyCode: string, version = '67') => {
  return url.replace('%apiCountryCode%', countyCode).replace('%apiVersion%', version);
};

export const filterObject = (obj: IKeyValueData): IKeyValueData => {
  return Object.entries<IKeyValueData>(obj)
    .filter(([, val]) => val !== undefined)
    .reduce(
      (newArray, [key, val]) => ({
        ...newArray,
        [key]: val,
      }),
      {},
    );
};

export const removeUndefinedValuesFromArray = (array: (string | undefined | null)[]): string[] => {
  const filteredArray: string[] = array.filter((val) => typeof val === 'string') as any as string[];
  return filteredArray;
};

export const replacePlaceholderInString = (
  transformableString: string,
  placeholder: IKeyValueData = {},
) => {
  if (!transformableString) {
    return '';
  }
  return Object.entries<string>(placeholder).reduce((transformedUrl, [key, value]) => {
    return transformedUrl.replace(`%${key}%`, value);
  }, transformableString);
};

export const getCapitalizedThemeName = () => {
  const { VITE_THEME } = import.meta.env;
  return `${VITE_THEME}`.charAt(0).toUpperCase() + `${VITE_THEME}`.slice(1);
};

export const getCookie = (name: string): string => {
  name += '=';
  const cookies = document.cookie.split(';');

  for (const element of cookies) {
    let c = element;
    while (c.startsWith(' ')) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return '';
};

export const isValidURL = (url: string): boolean => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const getHostName = (url: string): string => new URL(url).hostname;

export const hasSameOrigin = (url: string): boolean => {
  if (!isValidURL(url)) {
    return false;
  }
  if (import.meta.env.VITE_ENV === 'production') {
    return getHostName(url) === window.location.hostname;
  }
  return true;
};

export const isUserAuthenticated = (): boolean => {
  return getCookie('authToken').length > 0;
};

export function getVisiblePagesAmount(
  currentPage: number,
  pagesLength: number,
  isWideScreen = true,
): 1 | 2 {
  const isInPageRangeForTwoPages = !(currentPage === 1 || currentPage === pagesLength);
  return isInPageRangeForTwoPages && isWideScreen ? 2 : 1;
}

export function getTargetPageNumber(
  currentPage: number,
  direction: string,
  currentVisiblePages: number,
  isWideScreen?: boolean,
  targetPage?: number,
): number {
  let newPage;
  switch (direction) {
    case TRANSITION_TYPES.BACKWARD:
      newPage = currentPage - currentVisiblePages;
      break;
    case TRANSITION_TYPES.FORWARD:
      newPage = currentPage + currentVisiblePages;
      break;
    default:
      newPage = targetPage ?? currentPage;
  }

  if (isWideScreen && newPage % 2 !== 0 && newPage !== 1) {
    newPage -= 1;
  }

  return newPage || 1;
}

export function kauflandTrackingSessionStorage(
  key: 'aa_jumpmark',
  value: string,
  isDebugTrackingEnabled = false,
) {
  if (import.meta.env.VITE_THEME !== 'kaufland') {
    return;
  }

  sessionStorage.setItem(key, value);
  if (isDebugTrackingEnabled) {
    console.log('kaufland sessionStorage tracking', { key, value });
  }
}

export const dispatchCustomEvent = (eventName: string, detail: IKeyValueData = {}) => {
  const event = new CustomEvent(eventName, {
    detail,
  });
  dispatchEvent(event);
};

export function isAnimatedLinkType(displayType: ALinkDisplayTypes) {
  return (
    displayType === 'animated_gif' ||
    displayType === 'animation_image' ||
    displayType === 'animation_video'
  );
}

export const transformMobileApiProductToFlyerJsonFormat = (
  product: Product | ProductVariant,
): AProductData => {
  return {
    productId: String(product.productId),
    price: product?.price ?? '',
    title: product.title,
    url: product.url,
    image: product.image,
    erpNumber: product.erpNumber,
    brand: product?.brandName ?? '',
    // currency is provided via mobileApi countries endpoint
    currencyText: '',
    currencySymbol: '',
    // categoryPrimary is provided via mobileApi campaigns endpoint
    categoryPrimary: '',
  };
};
