import { getProductDetail } from 'api/products';
import ErrorComponent, { ERROR_CODES } from 'components/Error';
import Loading from 'components/Loading';
import { GlobalDataContext } from 'containers/ContentContainer/context';
import { LanguageContext } from 'providers/Language';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './ProductDetailIframe.module.scss';

type ProductDetailIframeProps = {
  readonly productId: string;
};

type ErrorType = 'none' | 'not_found' | 'network';

export default function ProductDetailIframe({ productId }: ProductDetailIframeProps) {
  const { t } = useContext(LanguageContext);
  const { id } = useContext(GlobalDataContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorType, setErrorType] = useState<ErrorType>('none');
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);

  const products = useSelector((state: AppState) => state.appData.products);
  const { countryCode, locale } = useSelector((state: AppState) => state.appData);

  const productTitle: string = useMemo(() => {
    const product = Object.values(products).find((p) => p.productId === productId);
    return product ? `${t('productdetail')} ${product.title}` : t('productdetail');
  }, [productId, products, t]);

  const fetchProductDetails = useCallback(async () => {
    if (!productId) return;

    let timer: NodeJS.Timeout;
    setIsLoading(true);
    setIframeSrc(null);
    setErrorType('none');

    try {
      const response = await getProductDetail(productId, countryCode, locale);
      if (response.status === 200 && response.data.canonicalUrl) {
        const iframeUrl = `${response.data.canonicalUrl}?msAbTestVariationKey=cx&layout=flyer&hidebanner=true&flyx=${id}`;
        timer = setTimeout(() => {
          setIframeSrc(iframeUrl);
          setIsLoading(false);
        }, 200);
      } else {
        setErrorType('not_found');
        window.open(`/p/product/p${productId}`, '_blank');
      }
    } catch {
      setErrorType('network');
    }
    return () => clearTimeout(timer);
  }, [countryCode, id, locale, productId]);

  useEffect(() => {
    fetchProductDetails();
  }, [fetchProductDetails]);

  const handleIframeLoad = (): void => {
    setIsLoading(false);
  };

  const handleIframeError = (): void => {
    setIsLoading(false);
    setErrorType('network');
  };

  if (!productId) return <Loading />;
  if (errorType !== 'none')
    return <ErrorComponent errorCode={ERROR_CODES.PRODUCT} isErrorInSidebar />;

  return (
    <section className={styles.wrapper}>
      {isLoading && <Loading />}
      {iframeSrc && (
        <iframe
          title={productTitle}
          className={styles.iframe}
          src={iframeSrc}
          onLoad={handleIframeLoad}
          onError={handleIframeError}
        />
      )}
    </section>
  );
}
