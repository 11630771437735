import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { getSearchParamsAsObject } from '../helper/js/helper';

const { VITE_API_URL, VITE_API_VERSION, VITE_ENV, VITE_MOBILE_API_URL, VITE_CMS_URL } = import.meta
  .env;

export const leafletCMS = (): AxiosInstance =>
  axios.create({
    baseURL: `${VITE_CMS_URL}/`,
  });

export const leafletAPI = (): AxiosInstance => {
  let baseURL = `${VITE_API_URL}/${VITE_API_VERSION}`;

  if (VITE_ENV !== 'production') {
    // to test new api features, it is possible to override the api baseUrl on non-prod envs
    const { apiUrl = VITE_API_URL, apiVersion = VITE_API_VERSION } = getSearchParamsAsObject();
    baseURL = `${decodeURIComponent(apiUrl)}/${apiVersion}`;
    console.info(`API URL has been set to ${baseURL} :-)`);
  }

  return axios.create({ baseURL });
};

export const mobileAPI = (): AxiosInstance =>
  axios.create({
    baseURL: `${VITE_MOBILE_API_URL}/`,
  });

export const cartAPI = (): AxiosInstance =>
  axios.create({
    baseURL: '/cart-api/',
  });

export const productAPI = (): AxiosInstance => {
  return axios.create({
    baseURL: `/p/api/`,
  });
};

export function checkResponse(response: AxiosResponse, property?: string): boolean {
  if (response?.data.success && (!property || response.data[property])) {
    return true;
  }
  throw new Error('API RESPONSE INVALID');
}
