import Brand from 'components/Brand';
import ButtonC, { BUTTON_HOVERSTYLE } from 'components/ButtonC';
import Header from 'components/Header';
import Account from 'components/Header/navigation/Account';
import Cart from 'components/Header/navigation/Cart';
import { LINK_TARGET } from 'components/Link';
import ProgressbarContainer from 'containers/ProgressbarContainer';
import StepperContainer from 'containers/StepperContainer';
import { VIEW } from 'helper/js/constants';
import { useFlippyNavigate, useFlippyView } from 'helper/js/hooks/router';
import { LanguageContext } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { flyerWishListCountSelector } from './selectors';

type Props = {
  hasError: boolean;
};

const HeaderContainer = ({ hasError }: Props) => {
  const {
    noMarginals,
    hasTopics,
    showSearch,
    shoppingCart: shoppingCartEnabled,
    myAccount: myAccountEnabled,
  } = useSelector((state: AppState) => state.settings);
  const { homeUrl, quitUrl } = useSelector((state: AppState) => state.appData.urls);
  const isSmMin = useSelector((state: AppState) => state.browser.isSmMin);
  const flippyView = useFlippyView();
  const isBetweenSMand850andMenuView = useSelector(
    (state: AppState) =>
      state.browser.isSmMin && state.browser.is850Max && flippyView === VIEW.MENU,
  );
  const wishListCount = useSelector((state: AppState) => flyerWishListCountSelector(state));
  const hasSeenNotifcation = useSelector(
    (state: AppState) =>
      state.notifications.data.filter(
        (notification) => state.notifications.seen.indexOf(notification.id) <= -1,
      ).length > 0,
    () => true,
  );
  const isWishListEmpty = wishListCount <= 0;
  const { t } = useContext(LanguageContext);
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  const flippyNavigate = useFlippyNavigate();

  const updateBrowserUrl = useCallback((view) => flippyNavigate({ view }), [flippyNavigate]);

  const updateView = useCallback(
    (view: string) => updateBrowserUrl(flippyView !== view ? view : VIEW.FLYER),
    [flippyView, updateBrowserUrl],
  );

  const handleToggleStepper = useCallback(() => {
    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.OVERVIEW_BUTTON_CLICKED,
      eventLabel: 'header',
    });
    updateView(VIEW.OVERVIEW);
  }, [trackEventUniversalAnalytics, updateView]);

  const handleToggleJumpmarks = useCallback(() => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.TOPICS_BUTTON_CLICKED);
    updateView(VIEW.JUMPMARKS);
  }, [trackEventUniversalAnalytics, updateView]);

  const handleToggleMenu = useCallback(() => {
    if (!hasError) {
      trackEventUniversalAnalytics(TRACKING_EVENTS.MENU_TOGGLE_CLICKED);
      trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.MENU_TOGGLE_BUTTON_CLICKED);
    } else {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.MENU_TOGGLE_CLICKED,
        eventCategory: 'flyer_error_page',
      });
    }
    updateView(VIEW.MENU);
  }, [hasError, trackEventDataLayerService, trackEventUniversalAnalytics, updateView]);

  const handleToggleSearch = useCallback(() => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.SEARCH_BUTTON_CLICKED);
    trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.SEARCH_BUTTON_CLICKED);
    updateView(VIEW.SEARCH);
  }, [trackEventDataLayerService, trackEventUniversalAnalytics, updateView]);

  const handleToggleWishList = useCallback(() => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.WISHLIST_BUTTON_CLICKED);
    trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.WISHLIST_BUTTON_CLICKED);
    updateView(VIEW.WISHLIST);
  }, [trackEventDataLayerService, trackEventUniversalAnalytics, updateView]);

  const handleExitFlyerButtonClicked = useCallback(() => {
    if (!hasError) {
      trackEventUniversalAnalytics(TRACKING_EVENTS.EXIT_FLYER_BUTTON_CLICKED);
    } else {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.EXIT_FLYER_BUTTON_CLICKED,
        eventCategory: 'flyer_error_page',
      });
    }
  }, [hasError, trackEventUniversalAnalytics]);

  const renderHeaderLeftContent = useMemo(
    () => <Brand href={homeUrl} hasError={hasError} />,
    [hasError, homeUrl],
  );

  const renderHeaderCenterContent = useMemo(
    () =>
      !hasError && isSmMin ? (
        <StepperContainer onClick={handleToggleStepper} showArrowButtons={false} />
      ) : null,
    [handleToggleStepper, hasError, isSmMin],
  );

  const renderHeaderRightContent = useMemo(
    () =>
      (import.meta.env.VITE_THEME !== 'kaufland' || !hasError) && (
        <>
          {!hasError && showSearch && (
            <ButtonC
              hoverStyle={BUTTON_HOVERSTYLE.ICON}
              inColumn
              icon={`magnifier--${import.meta.env.VITE_THEME}`}
              label={t('search')}
              onClick={handleToggleSearch}
              active={flippyView === VIEW.SEARCH}
              transformLabelToUppercase={false}
              className="button--hide-label--sm"
            />
          )}
          {!hasError && hasTopics && (
            <ButtonC
              hoverStyle={BUTTON_HOVERSTYLE.ICON}
              inColumn
              icon={`bookmark--${import.meta.env.VITE_THEME}`}
              label={t('jumpmarksButton')}
              onClick={handleToggleJumpmarks}
              active={flippyView === VIEW.JUMPMARKS}
              transformLabelToUppercase={false}
              className="button--hide-label--sm"
            />
          )}
          {!hasError && myAccountEnabled && <Account />}
          {!hasError && shoppingCartEnabled && <Cart />}
          <ButtonC
            hoverStyle={BUTTON_HOVERSTYLE.ICON}
            inColumn
            icon="bars-horizontal"
            label={t('menu')}
            indicator={hasSeenNotifcation}
            indicatorColor="danger"
            onClick={handleToggleMenu}
            active={flippyView === VIEW.MENU}
            transformLabelToUppercase={false}
            className="button--hide-label--sm"
          />

          {isSmMin && (
            <ButtonC
              hoverStyle={BUTTON_HOVERSTYLE.ICON}
              inColumn
              icon="close"
              label={t('quit')}
              onClick={handleExitFlyerButtonClicked}
              href={quitUrl}
              target={LINK_TARGET.SELF}
              transformLabelToUppercase={false}
              className="button--hide-label--sm"
            />
          )}
        </>
      ),
    [
      flippyView,
      handleExitFlyerButtonClicked,
      handleToggleJumpmarks,
      handleToggleMenu,
      handleToggleSearch,
      handleToggleWishList,
      hasError,
      hasSeenNotifcation,
      hasTopics,
      isSmMin,
      isWishListEmpty,
      myAccountEnabled,
      quitUrl,
      shoppingCartEnabled,
      showSearch,
      t,
      wishListCount,
    ],
  );

  const renderBottomContent = <ProgressbarContainer />;
  return !noMarginals ? (
    <Header
      left={renderHeaderLeftContent}
      center={renderHeaderCenterContent}
      right={renderHeaderRightContent}
      bottom={renderBottomContent}
      hideLeft={false}
      hideCenter={isBetweenSMand850andMenuView}
      hideRight={isBetweenSMand850andMenuView}
      hasError={hasError}
    />
  ) : null;
};

export default memo(HeaderContainer);
