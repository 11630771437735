import './NotificationButton.scss';

import classNames from 'classnames';
import ButtonC, { BUTTON_ALIGN_LABEL, BUTTON_HOVERSTYLE } from 'components/ButtonC';
import IconC from 'components/IconC';
import { LanguageContext } from 'providers/Language';
import { memo, useContext } from 'react';

import { TENANT } from '../../helper/js/constants';

type Props = {
  isVisible: boolean;
  hasNewNotification: boolean;
  onClick?: () => void;
};

const NotificationButton = ({ isVisible, onClick, hasNewNotification }: Props) => {
  const { t } = useContext(LanguageContext);
  const theme = import.meta.env.VITE_THEME;

  const notificationIconToggleClassNames = classNames({
    'notification__icon-toggle': true,
    'notification__icon-toggle--visibile': isVisible,
  });

  let arrowIcon = `arrow-up--${theme}`;
  if (theme === TENANT.KAUFLAND) {
    arrowIcon = isVisible ? `arrow-up--${theme}` : `arrow-down--${theme}`;
  }
  return (
    <div className="notification__headline">
      <ButtonC
        active={isVisible}
        hoverStyle={BUTTON_HOVERSTYLE.ICON}
        fullwidth
        icon={`bell--${theme}`}
        indicator={hasNewNotification}
        indicatorColor="danger"
        label={t('notificationHeadline')}
        onClick={onClick}
        alignLabel={BUTTON_ALIGN_LABEL.LEFT}
        transformLabelToUppercase={false}
        labelBold={false}>
        <span className={notificationIconToggleClassNames} data-testid="notificationIconWrapper">
          <IconC icon={arrowIcon} />
        </span>
      </ButtonC>
    </div>
  );
};

export default memo(NotificationButton);
